<div id="top"></div>
<mat-sidenav-container>
  <!-- Mobile / Main Menu -->
  <mat-sidenav #sidenav role="navigation" position="start">
    <layout-sidenav (sidenavClose)="sidenav.close()"></layout-sidenav>
  </mat-sidenav>

  <!-- Main Container -->
  <mat-sidenav-content>
    <!-- Header -->
    <layout-header (sidenavToggle)="sidenav.toggle()"></layout-header>


    <!-- Body -->
    <main>
      <app-bread-crumbs [deliminator]="'/'"></app-bread-crumbs>
      <router-outlet></router-outlet>
    </main>

    <order-at *ngIf="!router.url.includes('merchants')"></order-at>

    <!-- Footer -->
    <layout-footer></layout-footer>

  </mat-sidenav-content>
</mat-sidenav-container>
