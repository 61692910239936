import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmbedComponent } from './embed.component';


@NgModule({
  declarations: [
    EmbedComponent
  ],
  imports: [
    CommonModule, 
    MatCardModule
  ],
  exports: [
    EmbedComponent
  ],
})
export class EmbedModule { }
