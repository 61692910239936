export const environment = {
  production: true,
  graphQL: 'vN-FraOjx9ian_xYGCDKoLYUhxOIiu6X',
  // api: 'http://ec2-34-235-215-55.compute-1.amazonaws.com/api',
  api: 'https://cms.philamarketplace.com/api',

  // services: 'http://ec2-34-235-215-55.compute-1.amazonaws.com/actions/mphl-actions/mphl/',
  services: 'https://cms.philamarketplace.com/actions/mphl-actions/mphl/',

  // homeUrl: 'http://mphl-site.s3-website-us-east-1.amazonaws.com/'
  homeUrl: 'https://d2ko6t6i0h3pe0.cloudfront.net/'

};
