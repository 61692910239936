import { BreadCrumbsComponent } from './shared/components/breadcrumbs/breadcrumbs.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeaderModule } from './shared/components/header/header.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { SidenavModule } from './shared/components/sidenav/sidenav.module';
import { SharedModule } from './shared/shared.module';
import { HttpErrorInterceptor } from './shared/services/error-interceptor.service';
import { LinkHandleService } from './shared/services/link-handle.service';
import { InputMaskModule } from '@ngneat/input-mask';
import { SeoService } from './shared/services/seo.service';
import { RedirectGuard } from './shared/guards/redirect.guard';


@NgModule({
  declarations: [
    AppComponent,
    BreadCrumbsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    GraphQLModule,
    HttpClientModule,
    SharedModule,
    InputMaskModule.forRoot({ inputSelector: 'input', isAsync: true }),

    // Layout
    FooterModule,
    HeaderModule,
    MatSidenavModule,
    SidenavModule,
    InputMaskModule,
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
    },
    RedirectGuard,
    SeoService,
    {provide: 'googleTagManagerId',  useValue: 'GTM-TH5XKC8'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
