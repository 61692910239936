<mat-toolbar class="header">
  <div class="header__logo">
    <a href="/" class="header__logo-link"><img src="../../assets/images/mphl-logo--notOfficial.png" class="header__logo-icons"/></a>
  </div>
  <div class="header__end">
      <ul class="header__links">

          <li class="header__map">
            <a href="/map" class="header__map-link">
              <mat-icon [ngStyle]="{'color':'#1da8e0'}" class="header__map-icon">place</mat-icon>
              <span class="header__map-text">Map</span>
            </a>
          </li>

          <li class="header__map-line"></li>
          <li class="header__toggle">
            <button (click)="onToggleSidenav()">
              <mat-icon [ngStyle]="{'color':'#1da8e0'}">menu</mat-icon>
            </button>
          </li>
      </ul>
  </div>
</mat-toolbar>
