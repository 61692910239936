import { AccordionComponent } from './accordion.component'
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AccordionComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    RouterModule
  ],
  exports: [
    AccordionComponent
  ]
})
export class AccordionModule { }
