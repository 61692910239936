import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private descriptionTags:string[] = ['description', 'og:description', 'twitter:description'];
  private urlTags:string[] = ['og:url', 'twitter:url'];
  private titleTags:string[] = ['og:title', 'twitter:title'];
  private imageTags:string[] = ['og:image', 'twitter:image'];

  constructor(
    @Inject(DOCUMENT) private dom,
    private meta: Meta,
    private title: Title
  ) { }

  public setMeta(meta:any) {
    this.setTitle(meta.title);

    // Set Description
    this.descriptionTags.forEach((tag) => {
      this.addTag(tag, meta.description);
    });

    // Set Url
    this.urlTags.forEach((tag) => {
      this.addTag(tag, window.location.toString());
    });

    // Set Title
    this.titleTags.forEach((tag) => {
      this.addTag(tag, meta.title);
    });

    this.setGeneric();
    this.createHomeURL();
    this.createCanonicalURL();
  }


  private addTag(key:string, value: string) {
    this.meta.addTags([
      { name: key, content: value }
    ])
  }

  private createCanonicalURL() {
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
  }

  private createHomeURL() {
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'home');
    this.dom.head.appendChild(link);
    link.setAttribute('href', environment.homeUrl);
  }

  private setTitle(title: string) {
    this.title.setTitle(title);
  }

  private setGeneric() {
    this.meta.addTags([
      { name: 'fb:app_id', content: '208464355443' },
      { name: 'og:type',  content: 'website' },
      { name: 'og:locale', content: 'en_US' },
      { name: 'og:locale:alternate', content: 'en_US' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@PHLFoodandShops'}
    ])
  }
}
