<footer class="footer">
  <nav class="footer__menu">
    <ul class="footer__items">
      <li class="footer__item"><a class="footer__link footer__link--bold" routerLink="/directory">Directory</a></li>
      <li class="footer__item"><a class="footer__link footer__link--bold" routerLink="/deals">Deals</a></li>
      <li class="footer__item"><a class="footer__link footer__link--bold" routerLink="/events">Events</a></li>
      <li class="footer__item"><a class="footer__link footer__link--bold" routerLink="/map">Map</a></li>
      <li class="footer__item"><a class="footer__link footer__link--bold" routerLink="/press">Press</a></li>
      <li class="footer__item"><a class="footer__link footer__link--bold" routerLink="/contact">Contact</a></li>
      <li class="footer__item"><a class="footer__link footer__link--bold" routerLink="/merchants">Merchant Resources</a></li>
      <li class="footer__item"><a class="footer__link footer__link--bold" routerLink="/about">About</a>
        <ul class="footer__sub-menu">
          <li class="footer__item"><a class="footer__link" routerLink="/about/faq">FAQ</a></li>
          <li class="footer__item"><a class="footer__link" routerLink="/about/leasing">Leasing</a></li>
          <li class="footer__item"><a class="footer__link" routerLink="/about/community"> Community</a></li>
          <li class="footer__item"><a class="footer__link" routerLink="/about/careers"> Careers</a></li>
          <li class="footer__item"><a class="footer__link" routerLink="/about/team"> Executive Team</a></li>
          <!-- <li class="footer__item"><a class="footer__link" routerLink="/about/safety"> Health and Safety</a></li> -->
        </ul>
      </li>
    </ul>
  </nav>
  <section class="footer__location">
    <a class="footer__link" routerLink="/"><img src="../../assets/images/mphl-logo--notOfficial.png" class="header__logo-icons" /></a>
    <p class="footer__contact">
      <strong>Philadelphia International Airport</strong> <br />
      <span class="footer__limited">Terminal E - Upper Level</span> <br />
      <span class="footer__limited">Philadelphia, PA 19153</span> <br />
      <strong>tel: <a class="footer__link" href="tel:2159381200">215.937.1200</a></strong> <br />
      <strong>fax: <a class="footer__link" href="fax:2159371206">215.937.1206</a></strong>
      <span class="footer__social">
        <a href="https://twitter.com/phlfoodandshops/" target="_blank" class="footer__social-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="25.182" height="20.452" viewBox="0 0 25.182 20.452">
            <defs>
              <style>
                .tw {
                  fill:#072c62;
                }
              </style>
            </defs>
            <path class="tw" d="M22.593,8.478c.016.224.016.447.016.671A14.583,14.583,0,0,1,7.925,23.833,14.584,14.584,0,0,1,0,21.516a10.676,10.676,0,0,0,1.246.064,10.336,10.336,0,0,0,6.407-2.2A5.17,5.17,0,0,1,2.828,15.8a6.508,6.508,0,0,0,.975.08A5.458,5.458,0,0,0,5.161,15.7a5.162,5.162,0,0,1-4.138-5.065v-.064a5.2,5.2,0,0,0,2.333.655,5.169,5.169,0,0,1-1.6-6.9A14.67,14.67,0,0,0,12.4,9.724a5.826,5.826,0,0,1-.128-1.182A5.166,5.166,0,0,1,21.2,5.011a10.161,10.161,0,0,0,3.276-1.246A5.147,5.147,0,0,1,22.21,6.608a10.346,10.346,0,0,0,2.972-.8,11.094,11.094,0,0,1-2.588,2.668Z" transform="translate(0 -3.381)"/>
          </svg>
        </a>
        <a href="https://www.facebook.com/PHLFoodandShops/" target="_blank" class="footer__social-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.452" height="20.328" viewBox="0 0 20.452 20.328">
            <defs>
              <style>
                .fb {
                  fill:#072c62;
                }
              </style>
            </defs>
            <path class="fb" d="M20.452,10.226a10.226,10.226,0,1,0-11.824,10.1V13.182h-2.6V10.226h2.6V7.973a3.609,3.609,0,0,1,3.863-3.979,15.727,15.727,0,0,1,2.289.2V6.711H13.49a1.478,1.478,0,0,0-1.667,1.6v1.918H14.66l-.453,2.956H11.824v7.146A10.229,10.229,0,0,0,20.452,10.226Z"/>
          </svg>
        </a>
        <a href="https://www.instagram.com/phlfoodandshops/" target="_blank" class="footer__social-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.452" height="20.452" viewBox="0 0 20.452 20.452">
            <defs>
              <style>
                .ig {
                  fill:#072c62;
                }
              </style>
            </defs>
            <path class="ig" d="M10.226,0C7.448,0,7.1.013,6.01.061A7.54,7.54,0,0,0,3.528.537,5.008,5.008,0,0,0,1.716,1.716,4.99,4.99,0,0,0,.537,3.528,7.518,7.518,0,0,0,.061,6.01C.01,7.1,0,7.448,0,10.226s.013,3.125.061,4.216a7.545,7.545,0,0,0,.476,2.482,5.015,5.015,0,0,0,1.179,1.812,5,5,0,0,0,1.812,1.179,7.55,7.55,0,0,0,2.482.476c1.091.051,1.438.061,4.216.061s3.125-.013,4.216-.061a7.567,7.567,0,0,0,2.482-.476,5.226,5.226,0,0,0,2.991-2.991,7.545,7.545,0,0,0,.476-2.482c.051-1.091.061-1.438.061-4.216S20.439,7.1,20.391,6.01a7.563,7.563,0,0,0-.476-2.482,5.019,5.019,0,0,0-1.179-1.812A4.982,4.982,0,0,0,16.924.537,7.523,7.523,0,0,0,14.442.061C13.351.01,13,0,10.226,0Zm0,1.841c2.73,0,3.055.014,4.133.061a5.634,5.634,0,0,1,1.9.354A3.365,3.365,0,0,1,18.2,4.2a5.646,5.646,0,0,1,.352,1.9c.049,1.079.06,1.4.06,4.133s-.013,3.055-.063,4.133a5.755,5.755,0,0,1-.359,1.9,3.247,3.247,0,0,1-.766,1.178,3.19,3.19,0,0,1-1.176.764,5.688,5.688,0,0,1-1.9.352c-1.086.049-1.405.06-4.141.06S7.145,18.6,6.06,18.547a5.8,5.8,0,0,1-1.905-.359,3.167,3.167,0,0,1-1.175-.766,3.105,3.105,0,0,1-.767-1.176,5.8,5.8,0,0,1-.358-1.9c-.038-1.074-.052-1.405-.052-4.128s.014-3.056.052-4.142a5.8,5.8,0,0,1,.358-1.9A3.031,3.031,0,0,1,2.979,2.99a3.025,3.025,0,0,1,1.175-.765,5.66,5.66,0,0,1,1.893-.359c1.087-.038,1.406-.051,4.141-.051l.038.026Zm0,3.134a5.251,5.251,0,1,0,5.251,5.251A5.251,5.251,0,0,0,10.226,4.975Zm0,8.66a3.409,3.409,0,1,1,3.409-3.409A3.408,3.408,0,0,1,10.226,13.635Zm6.686-8.867a1.227,1.227,0,1,1-1.227-1.226A1.228,1.228,0,0,1,16.912,4.768Z" transform="translate(0 0)"/>
          </svg>
        </a>
      </span>
      <!-- Insert Social Media Links -->
    </p>

  </section>
  <div class="footer__legal">
    <div class="footer__policy">
      <a href="/legal/privacy" class="footer__link">Privacy Policy</a>
      <a href="/legal/terms" class="footer__link">Terms and Conditions</a>
    </div>
    <div class="footer__copy">
      <p class="footer__limited">&copy; 2022 Philadelphia International Airport</p>
    </div>
  </div>
</footer>

<!-- <mat-toolbar class="footer md-tall">
  <mat-toolbar-row class="footer__header footer__row">
    <div class="footer__item"><a routerLink="/directory">Directory</a></div>
    <div class="footer__item"><a routerLink="/deals">Deals</a></div>
    <div class="footer__item"><a routerLink="/events">Events</a></div>
    <div class="footer__item"><a routerLink="/map">Map</a></div>
    <div class="footer__item"><a routerLink="/merchants">Merchant Resources</a></div>
    <div class="footer__item"><a routerLink="/contact">Contact</a></div>
    <div class="footer__item">
      <mat-nav-list class="footer__about-links">
        <a mat-list-item routerLink="/about" class="footer__link-header">About</a>
        <a mat-list-item routerLink="/about/faq"><span class="footer__list-link">FAQ</span></a>
        <a mat-list-item routerLink="/about/community"> <span class="footer__list-link">Community</span></a>
        <a mat-list-item routerLink="/about/careers"> <span class="footer__list-link">Careers</span></a>
        <a mat-list-item routerLink="/about/team"> <span class="footer__list-link">Executive Team</span></a>
        <a mat-list-item routerLink="/about/safety"> <span class="footer__list-link">Health and Safety</span></a>
      </mat-nav-list>
    </div>
    <div class="footer__item">
      <mat-nav-list class="footer__info-links">
        <a mat-list-item routerLink="/"><img src="../../assets/icons/footer-nav.svg" class="header__logo-icons" /></a>
        <mat-list-item class="footer__info-header">Philadelphia International Airport</mat-list-item>
        <mat-list-item class="footer__info-text">Terminal E - Upper Level</mat-list-item>
        <mat-list-item class="footer__info-text">Philadelphia, PA 19153</mat-list-item>
        <a mat-list-item href="tel:215-937-1200"><span class="footer__info-link">tel: 215-937-1200</span></a>
        <a mat-list-item href="tel: 215-937-1206"> <span class="footer__info-link">fax: 215-937-1206</span></a>
        <mat-list-item class="footer__icons">
          <img src="../../assets/icons/mphl-linkedin.svg" class="footer__social-icons" />
          <img src="../../assets/icons/mphl-insta.svg" class="footer__social-icons" />
          <img src="../../assets/icons/mphl-fb.svg" class="footer__social-icons" />
        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row class="footer__footer footer__row">
    <mat-nav-list class="footer__links">
      <a mat-list-item routerLink="/legal"><span class="footer__legal-link">Privacy Statement</span></a>
      <a mat-list-item routerLink="/legal"><span class="footer__legal-link">Terms and Conditions</span></a>
    </mat-nav-list>

    <div class="footer__copyright">© 2021 Philadelphia International Airport</div>
  </mat-toolbar-row>
</mat-toolbar> -->
