import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Philla Marketplace';

  constructor(
    public router: Router,
    private gtmService: GoogleTagManagerService
  ) {
    router.events.subscribe((y) => {
      if(y instanceof NavigationEnd && (typeof fbq !== 'undefined')) {
        fbq('track', 'PageView');
      }
    })
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: event.url,
        };

        this.gtmService.pushTag(gtmTag);
      }

      if (!(event instanceof NavigationEnd)) {
        return;
      }

      let top = document.querySelector('.header__logo-icons');
      top.scrollIntoView(false);
    });
  }
}
