import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { shareReplay } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';

import Authenticate from '@gql/auth/login.gql';
import RefreshToken from '@gql/auth/refresh.gql';
import Logout from '@gql/auth/logout.gql';
import ForgottenPassword from '@gql/auth/forgotPassword.gql';
import SetPassword from '@gql/auth/setPassword.gql';


const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userCache$?: Observable<any>;

  constructor(private apollo: Apollo) {}

  get user() {
    if (!this.userCache$) {
      (this.userCache$ = this.requestUser().pipe(map((res) => res))),
        shareReplay(CACHE_SIZE);
    }

    return this.userCache$;
  }

  forgotPassword(email: any): Observable<any> {
    return new Observable((observer) => {
      this.apollo
        .mutate<any>({
          variables: {
            email: email
          },
          mutation: ForgottenPassword
        }).subscribe(({data}) => {
          observer.next({status: true, message: data.forgottenPassword });
          observer.complete();
        });
    })
  }

  resetPassword(reset:any): Observable<any> {
    return new Observable((observer) => {
      this.apollo.mutate<any>({
        variables: {
          id: reset.id,
          code: reset.code,
          password: reset.password
        },
        mutation:SetPassword
      }).subscribe(({data}) => {
        observer.next({status: true, message: data});
        observer.complete();
      }, (error) => {
        observer.next({status: false, message: error});
        observer.complete();
      })
    })
  }

  // Login and set the user token
  userLogin(login: any): Observable<response> {
    let now = new Date();

    return new Observable((observer) => {
      this.apollo
        .mutate<any>({
          variables: {
            email: login.username,
            password: login.password,
          },
          mutation: Authenticate,
        })
        .subscribe(
          ({ data }) => {
            this.setToken(data);
            this.startRefreshTokenTimer(data.authenticate.jwtExpiresAt);

            observer.next({ status: true });
            observer.complete();
          },
          (error) => {
            observer.next({ status: false, message: error });
            observer.complete();
          }
        );
    });
  }

  // Destroy the user token.
  userLogout(): Observable<boolean> {
    if(typeof this.apollo == 'undefined') {
      localStorage.removeItem('access_token');
      this.stopRefreshTokenTimer();
    }

    return new Observable((observer) => {
      this.apollo
      .mutate<any>({
        variables: {
          token: this.refresh,
        },
        mutation: Logout
      }).subscribe(({ data }) => {
          localStorage.removeItem('access_token');
          this.stopRefreshTokenTimer();

          observer.next(true);
          observer.complete();
      });
    })
  }

  // BUG: this.apollo is undefined
  refreshToken() {
    if(typeof this.apollo == 'undefined') {
      this.userLogout().subscribe(res => res);
        localStorage.removeItem('access_token');
        this.stopRefreshTokenTimer();
    }

    return new Observable((observer) => {
      this.apollo
        .mutate<any>({
          variables: {
            token: this.refresh
          },
          mutation: RefreshToken
        }).subscribe(({data}) => {
          this.setToken({ authenticate: data.refreshToken });
          this.startRefreshTokenTimer(data.refreshToken.jwtExpiresAt);
          observer.next({ status: true });
          observer.complete();
        },
        (error) => {
          observer.next({ status: false, message: error });
          observer.complete();
        }
      );
    })
  }

  // Get user details via graphql.
  requestUser(): Observable<any> {
    return new Observable((observer) => {
      let token = localStorage.getItem('access_token');

      observer.next(token);
      observer.complete();
    });
  }

  validateToken(token):void {
    if(token == null) return null;

    let auth = JSON.parse(token);
    let now = new Date();

    if(now.getTime() > auth.expiry) {
      localStorage.removeItem('access_token');
      return null;
    }

    // Start the refresh countdown
    this.startRefreshTokenTimer(auth.expiry);

    return auth.value;
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer(expiry) {
    const timeout = new Date(expiry).getTime() -  Date.now() - (60 * 2000);

    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken().subscribe()
    }, timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private setToken(auth) {
    let now = new Date();

    localStorage.setItem(
      'access_token',
      JSON.stringify({
        value: auth.authenticate.jwt,
        refresh: auth.authenticate.refreshToken,
        expiry:  auth.authenticate.jwtExpiresAt,
      })
    );
  }

  private get refresh() {
    return JSON.parse(localStorage.getItem('access_token')).refresh;
  }
}

interface response {
  status: boolean;
  message?: string;
  code?: number;
}
