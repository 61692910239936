import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotLinksComponent } from './hot-links.component';
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { AssetModule } from '../../asset/asset.module';

@NgModule({
  declarations: [
    HotLinksComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatGridListModule,
    MatIconModule,
    AssetModule,
  ],
  exports: [
    HotLinksComponent
  ]
})
export class HotLinksModule { }
