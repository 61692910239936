import { VendorCardComponent } from './vendor-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CategoriesModule } from '../../categories/categories.module';

@NgModule({
  declarations: [
    VendorCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CategoriesModule, 
    MatIconModule
  ],
  exports: [
    VendorCardComponent
  ]
})
export class VendorCardModule { }
