// TODO: This needs to get its routes from a service
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from "@angular/router";
import { filter } from "rxjs/operators";
import { Input } from "@angular/core";
import { Breadcrumb } from "../../interface";

@Component({
  selector: "app-bread-crumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"]
})
export class BreadCrumbsComponent implements OnInit {
  @Input()
  public deliminator: string = ">";
  public breadcrumbs: Breadcrumb[];
  public lastBreadcrumb: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    let breadcrumb: Breadcrumb = {
      label: 'Home',
      url: ''
    };

    this.router.events
    .pipe(
      // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
      filter((event) => event instanceof NavigationEnd)
    )

    .subscribe((event) => {
      // Construct the breadcrumb hierarchy
      let root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
       this.breadcrumbs = [breadcrumb, ...this.breadcrumbs];
    });
  }


  private getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'title';

    //get the child routes
    let children: ActivatedRoute[] = route.children;


    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;

    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET || child.snapshot.url.length==0) {
        continue;
      }

      // //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      //get the route's URL segment
      let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");
      let labelURL: string = child.snapshot.url.map(segment => segment.path).join("/");
      let label = labelURL.split("-").join(" ");

      //append route URL to URL
      url += `/${routeURL}`;

      //add breadcrumb
      let breadcrumb: Breadcrumb = {
        // label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        label: label,
        url: url
      };
      breadcrumbs.push(breadcrumb);

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }
}
