import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesComponent } from './categories.component';
import { MatChipsModule } from '@angular/material/chips';


@NgModule({
  declarations: [
    CategoriesComponent
  ],
  imports: [
    CommonModule, 
    MatChipsModule
  ], 
  exports: [
    CategoriesComponent
  ]
})
export class CategoriesModule { }
