import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxedSideComponent } from './boxed-side.component';
import { AssetModule } from '../asset/asset.module';

@NgModule({
  declarations: [
    BoxedSideComponent
  ],
  imports: [
    CommonModule,
    AssetModule
  ],
  exports: [
    BoxedSideComponent
  ]
})
export class BoxedSideModule { }
