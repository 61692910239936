import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectGuard } from './shared/guards/redirect.guard';


const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'directory',  data: { title: 'Directory' }, loadChildren: () => import('./pages/directory/directory.module').then(m => m.DirectoryModule) },
  { path: 'deals',      data: { title: 'Deals' }, loadChildren: () => import('./pages/deals/deals.module').then(m => m.DealsModule) },
  { path: 'events',     data: { title: 'Events' }, loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule) },
  { path: 'about',      data: { title: 'About' }, loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
  { path: 'press',      data: { title: 'Press' }, loadChildren: () => import('./pages/press/press.module').then(m => m.PressModule) },
  { path: 'merchants',  data: { title: 'Merchants' }, loadChildren: () => import('./pages/merchants/merchants.module').then(m => m.MerchantsModule) },
  { path: 'map',        data: { title: 'Map' }, loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule) },
  { path: 'contact',    data: { title: 'Contact' }, loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
  { path: 'legal',      data: { title: 'Legal' }, loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalModule) },
  { path: 'localeats',  redirectTo: 'local-eats-at-phl-airport' , pathMatch: 'full' },
  { path: '2022-be-relax-staff-offer',      data: { title: 'Be Relax Staff Offer' }, loadChildren: () => import('./pages/be-relax/be-relax.module').then(m => m.BeRelaxModule) },
  { path: 'local-eats-at-phl-airport',
    canActivate: [RedirectGuard],
    data: {
      externalUrl: 'https://visit.philamarketplace.com/local-eats-at-phl-airport/',
      title: 'Local Eats at PHL Airport'
    }, loadChildren: () => import('./pages/local-eats/local-eats.module').then(m => m.LocalEatsModule) },
  { path: 'ewb',        data: { title: ' ' }, loadChildren: () => import('./pages/ewb/ewb.module').then(m => m.ewbModule) },
  { path: 'error',      data: { title: 'error' }, loadChildren: () => import('./pages/errors/errors.module').then(m => m.ErrorsModule) },
  { path: '**',         data: { title: '404' }, loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
