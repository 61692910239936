import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
declare var ga;

@Component({
  selector: 'order-at',
  templateUrl: './order-at.component.html',
  styleUrls: ['./order-at.component.scss']
})
export class OrderAtComponent implements OnInit {
  public show:boolean = false;

  constructor(
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.show = true;
    }, 2000)
  }

  clickEvent(): void {

    console.log('event');
    window.open("http://orderatphl.com/", "_blank");
    ga('send','event', ['orderatphl'], ['sticky_cta_click']);
  }
}
