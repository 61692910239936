import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // redirects...not sure if this works or how to test it, maybe not all of these are needed?
        if (error instanceof HttpErrorResponse && error.status === 401) {
        } else if (error instanceof HttpErrorResponse && error.status === 500) {
          console.warn('500 Internal Server Error');
          this.router.navigate(['/error']);
          return throwError(error);
        } else if (error instanceof HttpErrorResponse && error.status === 502) {
          console.warn('502 Bad Gateway');
          this.router.navigate(['/error']);
          return throwError(error);
        } else if (error instanceof HttpErrorResponse && error.status === 503) {
          console.warn('503 Service Unavailable');
          this.router.navigate(['/error']);
          return throwError(error);
        } else if (error instanceof HttpErrorResponse && error.status === 504) {
          console.warn('504 Gateway Timeout');
          this.router.navigate(['/error']);
          return throwError(error);
        } else if (error instanceof HttpErrorResponse && error.status === 0) {
          console.warn('503 Service Temporarily Unavailable');
          this.router.navigate(['/error']);
          return throwError(error);
        } else {
          this.router.navigate(['/error']);
          return throwError(error);
        }
      })
    );
  }
}
