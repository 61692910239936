import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinkHandleService {
  private linkPattern = new RegExp("(https?:\/\/(.+?\.)?(\w)\.?)", 'gi');
  private domainPattern = new RegExp("(https?:\/\/(.+?\.)?philamarketplace\.com?)", 'g');

  constructor() { }

  getLink(link:string) {
    if(this.domainPattern.test(link) === true) {
      // Our site, but wrong format
      let returnLink = link.replace(this.domainPattern, "");

      return {
        routerLink: returnLink
      }
    } else if (this.linkPattern.test(link)) {
      // Other UR
      return {
        href: link,
        target: "_BLANK"
      };
    } else {
      // Make sure theres a starting slash
      link = (link.charAt(0) != '/') ? '/' + link : link;
      return {
        routerLink: link
      }
    }
  }
}
