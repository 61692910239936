<mat-nav-list class="side-nav">
  <h1 class="nav-caption" class="side-nav__header" (click)="onSidenavClose()">Menu</h1>

  <a mat-list-item routerLink="/directory" (click)="onSidenavClose()">
    <span class="nav-caption side-nav__link">Directory</span>
  </a>

  <a mat-list-item routerLink="/about/leasing" (click)="onSidenavClose()">
    <span class="nav-caption side-nav__link">Leasing</span>
  </a>

  <a mat-list-item routerLink="/deals" (click)="onSidenavClose()">
    <span class="nav-caption side-nav__link">Deals</span>
  </a>

  <a mat-list-item routerLink="/events" (click)="onSidenavClose()">
    <span class="nav-caption side-nav__link">Events</span>
  </a>

  <a mat-list-item routerLink="/map" (click)="onSidenavClose()">
    <span class="nav-caption side-nav__link">Map</span>
  </a>

  <a mat-list-item routerLink="/press" (click)="onSidenavClose()">
    <span class="nav-caption side-nav__link">Press</span>
  </a>

  <a mat-list-item routerLink="/contact" (click)="onSidenavClose()">
    <span class="nav-caption side-nav__link">Contact</span>
  </a>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <div class="side-nav__about-wrapper">
        <a mat-list-item routerLink="/about" (click)="onSidenavClose()">
          <span class="nav-caption side-nav__link" routerLink="/about">About</span>
        </a>
        <mat-icon [ngStyle]="{'color':'#1da8e0'}" class="side-nav__icon">keyboard_arrow_down</mat-icon>
      </div>
    </mat-expansion-panel-header>

    <a mat-list-item routerLink="/about/faq" (click)="onSidenavClose()">
      <span class="nav-caption side-nav__about-link">FAQ</span>
    </a>

    <a mat-list-item routerLink="/about/community" (click)="onSidenavClose()">
      <span class="nav-caption side-nav__about-link">Community</span>
    </a>

    <a mat-list-item routerLink="/about/team" (click)="onSidenavClose()">
      <span class="nav-caption side-nav__about-link">Executive Team</span>
    </a>

    <a mat-list-item routerLink="/about/safety" (click)="onSidenavClose()">
      <span class="nav-caption side-nav__about-link">Health and Safety</span>
    </a>
  </mat-expansion-panel>

  <a mat-list-item routerLink="/about/careers" (click)="onSidenavClose()">
    <span class="nav-caption side-nav__link">Careers</span>
  </a>

  <a mat-list-item routerLink="/merchants" (click)="onSidenavClose()">
    <span class="nav-caption side-nav__link">Merchant Resources</span>
  </a>
</mat-nav-list>
