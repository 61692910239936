import { HotLinksModule } from './components/hot-links/hot-links/hot-links.module';
import { EmbedModule } from './components/embed/embed.module';
import { FilterModule } from './components/filter/filter.module';
import { CategoriesModule } from './components/categories/categories.module';
import { ImageModule } from './components/image/image.module';
import { BannerFoldModule } from './components/banner-fold/banner-fold.module';
import { BoxedIslandModule } from './components/boxed-island/boxed-island.module';
import { BoxedSideModule } from './components/boxed-side/boxed-side.module';
import { BoxedFullModule } from '../shared/components/boxed-full/boxed-full.module';
import { NgModule } from '@angular/core';
import { DealsModule } from './components/cards';
import { TerminalModule } from './components/terminal/terminal.module';
import { TopLevelModule } from '../shared/components/banner/top-level.module';
import { VendorCardModule } from './components/cards/vendor-card/vendor-card.module';
import { NotificationModule } from './components/notification/notification.module';
import { BodyCopyModule } from './components/body-copy/body-copy.module';
import { ArticleModule } from './components/cards/article/article.module';
import { AccordionModule } from './components/accordion/accordion.module';
import { ContentBlockModule } from './components/content-block/content-block.module';
import { PullQuoteGenModule } from './components/pull-quote/pull-quote.module';
import { AssetModule } from './components/asset/asset.module';
import { LinkHandleService } from './services/link-handle.service';
import { OrderAtModule } from './components/order-at/order-at.module';
import { DividingHeaderModule } from './components/dividing-header/dividing-header.module';
import { IconBlockModule } from './components/icon-block/icon-block.module';

@NgModule({
  declarations: [
  ],
  imports: [
    AccordionModule,
    ArticleModule,
    AssetModule,
    BannerFoldModule,
    BodyCopyModule,
    BoxedIslandModule,
    BoxedFullModule,
    BoxedSideModule,
    CategoriesModule,
    ContentBlockModule,
    DealsModule,
    EmbedModule,
    FilterModule,
    HotLinksModule,
    ImageModule,
    NotificationModule,
    PullQuoteGenModule,
    TerminalModule,
    TopLevelModule,
    VendorCardModule,
    OrderAtModule,
    IconBlockModule,
    DividingHeaderModule
  ],
  exports: [
    AccordionModule,
    ArticleModule,
    AssetModule,
    BannerFoldModule,
    BodyCopyModule,
    BoxedIslandModule,
    BoxedFullModule,
    BoxedSideModule,
    CategoriesModule,
    ContentBlockModule,
    DealsModule,
    EmbedModule,
    FilterModule,
    HotLinksModule,
    ImageModule,
    NotificationModule,
    PullQuoteGenModule,
    TerminalModule,
    TopLevelModule,
    VendorCardModule,
    OrderAtModule,
    IconBlockModule,
    DividingHeaderModule
  ],
  providers: [LinkHandleService],
})
export class SharedModule {}
