import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconBlockComponent } from './icon-block.component';



@NgModule({
  declarations: [IconBlockComponent],
  imports: [
    CommonModule
  ],
  exports: [IconBlockComponent]
})
export class IconBlockModule { }
