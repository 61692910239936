import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { AuthService } from './shared/services/auth.service';

const uri = environment.api; // <-- add the URL of the GraphQL server here
export function createApollo(
  httpLink: HttpLink,
  _auth: AuthService
) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
      'Access-Control-Allow-Origin': '*',
    }
  }));

  const auth = setContext((operation, context) => {
    const DEFAULT = `Basic ${environment.graphQL}`;
    let token, USER;

    // Check if a token is defined
    if((token = localStorage.getItem('access_token')) !== null) {
      if((token = AuthService.prototype.validateToken(token)) !== null) {
        USER = `JWT ${token}`;
      }
    }

    return {
      headers: {
        Authorization: `${(USER) ?? DEFAULT}`
      }
    };
  });

  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
